<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div style="display:inline-block; margin:2%;">
    <el-collapse>
      <h2>
        Port Capacity Calculator
      </h2>
      <div
        id="portForm"
        class="calcDiv"
      >
        <br>Pixels/Panel X:<br>
        <el-input
          v-model="panelX"
          type="form"
          placeholder="pixel X"
        /><br>
        Pixels/Panel Y:<br>
        <el-input
          v-model="panelY"
          type="form"
          placeholder="pixel Y"
        /><br>
        Refresh:<br>
        <el-input
          v-model="refresh"
          type="form"
          placeholder="hz"
        /><br>
        Bit Depth:<br>
        <el-input
          v-model="bitDepth"
          type="form"
          placeholder="bits"
        /><br>
        <!-- Sub-sampling:<br>
        <el-select
          v-model="sampling"
          name="sample"
          placeholder="sampling"
        >
          <el-option
            v-for="item in samplingOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select><br> -->
        Link Speed:<br>           
        <div class="right">
          <el-tooltip
            class="item"
            effect="dark"
            content="Most processors utilize 1G links from distribution boxes (switches) to panels. 10G links are generally used from fiber links (or high end ethernet)."
            placement="top"
          >
            <el-select
              v-model="availBandwidth"
              placeholder="link speed"
            >
              <el-option
                v-for="item in availBand"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-tooltip><br>
        </div>
      </div>  
      <p class="article" style="font-size: 8pt; text-align: center;">
        {{ availBandwidth }} / ({{ panelX }} x {{ panelY }} x {{ refresh }} x {{ bitDepth }} x {{ sampling }})
      </p>       
      <el-input 
        v-model="totalPixels" 
        class="output"  
      /><br><br>
    </el-collapse>
  </div>
</template>

<script>
    export default {
        name: 'PortCapacityCalc',
        data() {
            return {
                pCalcState: true,
                panelX: '176',
                panelY: '176',
                availBandwidth: '1000000000',
                refresh: '60',
                bitDepth: '10',
                sampling: '3',
                options: [{
                  value: '8',
                  label: '8 bit (per color)'
                  }, {
                  value: '10',
                  label: '10 bit (per color)'
                  },{
                  value: '12',
                  label: '12 bit (per color)'
                  }
                ],
                availBand: [{
                  value: '1000000000',
                  label: '1 Gbps'
                  }, {
                  value: '2500000000',
                  label: '2.5 Gbps'
                  },{
                  value: '10000000000',
                  label: '10 Gbps'
                  }
                ],
                samplingOptions: [{
                  value: '2',
                  label: '4:2:2'
                  }, {
                  value: '3',
                  label: '4:4:4 / RGB'
                  }],
                inputCables: [{
                  value: '10.2',
                  label: 'HDMI 1.4'
                  }, {
                  value: '18.0',
                  label: 'HDMI 2.0'
                  }, {
                  value: '48.0',
                  label: 'HDMI 2.1'
                  },  {
                  value: '21.6',
                  label: 'DP 1.2'
                  },{
                  value: '32.4',
                  label: 'DP 1.4'
                  },{
                  value: '80.0',
                  label: 'DP 2.0'
                }],
                value: ''

            }
        },  
       computed: {
            totalPixels: {
                get() {
                  var actualValue = (parseFloat((this.availBandwidth/(this.panelX*this.panelY*this.refresh*this.bitDepth*this.sampling)*0.9)).toFixed(0) + " panels");
                  return actualValue;
                },
                set(val) {
                    this.actualValue = val;
                }
            }

        },       
        methods: {
            popupcollapse: function() {
                var popup = document.getElementById("popcollapse");
                popup.classList.toggle("show");
            }            
        }
    };

</script>

<style>

#portMain {
    text-align: center;
    border-style: solid;
    border-width: 3px;
    border-radius: 20px;
}
#portField {
    vertical-align: middle;
    border-radius: 20px;
}
</style>