<template>
  <div>
    <el-collapse 
      id="calcPlaceDiv" 
      style="margin-top: 2%;"
    >
      <br>
      <el-select
        v-model="value1"
        style="display: block;"
        multiple
        placeholder="Select Calculators"
        @change="updateVisibility"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div class="calcShowDiv">
        <powerCalculator v-show="showPowerCalculator" />
        <portCapacityCalc v-show="showPortCapacityCalculator" />
        <bandwidthCalc v-show="showBandCalculator" />
        <xyYCalc v-show="showLxyCalculator" />
      </div>
    </el-collapse><br>
  </div>
</template>

<script>
import powerCalculator from './calculators/powerCalculator.vue'
import portCapacityCalc from './calculators/portCapacityCalc.vue'
import bandwidthCalc from './calculators/bandwidthCalc.vue'
import xyYCalc from './calculators/xyYCalc.vue'

export default {
  name: 'CalculatorPage',
  components: {
    powerCalculator,
    portCapacityCalc,
    bandwidthCalc,
    xyYCalc,
  },
  data: function() {
    return {
      options: [
        {
          value: 'powershow',
          label: 'Power Calculator'
        }, {
          value: 'portShow',
          label: 'Port Capacity Calculator'
        }, {
          value: 'bandShow',
          label: 'Bandwidth Calculator'
        }, {
          value: 'LxyShow',
          label: 'Lxy <-> XYZ Calculator'
        }, {

        }
      ],
      value1: [],
      showPowerCalculator: false,
      showPortCapacityCalculator: false,
      showBandCalculator: false,
      showLxyCalculator: false,
      // Add visibility properties for other components
    }
  },
  methods: {
    updateVisibility() {
      this.showPowerCalculator = this.value1.includes('powershow');
      this.showPortCapacityCalculator = this.value1.includes('portShow');
      this.showBandCalculator = this.value1.includes('bandShow');
      this.showLxyCalculator = this.value1.includes('LxyShow');
      // Update visibility for other components similarly
    }
  }
};
</script>
<style scoped>
.calcShowDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>