import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        title: 'LED Corner',
        canvasInfo: {
            totalWeight: 0,
            totalWidth: 0,
            totalHeight: 0,
            totalAmps:0
        },
        panelConst: {
            panelName: 'Panel',
            pppWidth: 176,
            pppHeight: 176,
            watts: 60,
            pounds: 15,
            volts: 208,
            color: "green"
        },
        wallConst: {
            widthIn: 10,
            heightIn: 10,
            xPosition: 0,
            yPosition: 0
        }
    }
})