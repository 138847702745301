<template>
  <div>
    <el-collapse>
      <h1>Notepad</h1>
      <el-input
        v-model="notes"
        type="textarea"
        style="width: 90%;"
        placeholder="Type your notes here..."
      />
    </el-collapse>
  </div>
</template>

<script>
export default {
    data() {
        return {
            notes: ""
        };
    },
    methods: {
    }
};
</script>

<style>
.drop-area {
    width: 300px;
    height: 200px;
    border: 2px dashed #ccc;
    margin-top: 20px;
}
</style>