<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div style="display:inline-block; margin: 2%;">
    <div style="width:100%">
      <el-collapse>
        <h2>
          Power Calculator
        </h2>
        <div class="calcDiv">
          <br>Panel Power (watts):<br>
          <el-input
            id="pwrWatts"
            v-model="watts"
            type="form"
            placeholder="watts/panel"
            value=""
          /><br>
          # of Panels:<br>
          <el-input
            id="pwrTotalPanels"
            v-model="panelCount"
            placeholder="# of panels..."
            type="form"
            value=""
          /><br>
          Volts: <br>
          <el-select
            v-model="pwrVolts"
            name="volts"
            placeholder="Volts"
          >
            <el-option
              v-for="item in options"
              :key="item.value" 
              :label="item.label"
              :value="item.value"
            />
          </el-select><br>
          <p class="article" style="font-size: 8pt; text-align: center;">
            ({{ watts }} watts / {{ pwrVolts }} volts) x {{ panelCount }} panels
          </p>      
          <el-input v-model="pwrSolution" /><br><br>
        </div>
      </el-collapse>
    </div>
  </div>
</template>

<script>

    export default {
        name: 'PowerCalculator',
        data() {
            return {
                btnState: true,
                watts: '25',
                pwrVolts: '208',
                panelCount: '200',
                options: [{
                    value: '110',
                    label: '110v'
                    }, {
                    value: '208',
                    label: '208v'
                    }, {
                    value: '240',
                    label: '240v'
                    }],
                value: ''
                }
        },          
        computed: {
            pwrSolution: {
                get() {
                    var actualValue = (((this.watts / this.pwrVolts) * this.panelCount).toFixed(3) + " amps");
                    return actualValue;
                },
                set(val) {
                    this.actualValue = val;
                },
            },
            count () {
                return this.$store.state.count
                }
        },      
        methods: {
            removeOne1 () {
                this.$store.dispatch('removeOne')
            }
        } 
    };

</script>

<style>

#powerDiv {
    border-radius: 20px;
}
</style>