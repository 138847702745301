<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div style="display:inline-block; margin:2%;">
    <el-collapse>
      <h2>
        Data Rate Bandwidth Calculator
      </h2>
      <div>
        Horizontal Pixels:<br>
        <el-input
          v-model="Hinput"
          type="form"
          placeholder="horizontal pixels"
        /><br>
        Vertical Pixels:<br>
        <el-input
          v-model="Vinput"
          type="form"
          placeholder="vertical pixels"
        /><br>
        Bit Depth:<br>
        <el-select
          id="pwrVolts"
          v-model="bitDepth"
          name="bitD"
          placeholder="bits per color"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select><br>
        Sub-sampling:<br>
        <el-select
          v-model="sampling"
          name="sample"
          placeholder="sampling"
        >
          <el-option
            v-for="item in samplingOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select><br>
        Frame Rate:<br>
        <el-input
          v-model="refresh"
          type="form"
          placeholder="frame rate"
        /><br>
      </div><br>
      <p class="article" style="font-size: 8pt; text-align: center;">
        ({{ Hinput }} * {{ Vinput }} * {{ refresh }} * {{ bitDepth }} * {{ sampling }}) / 1000000000
      </p><br> 
      Total Data Bandwidth:<br>
      <el-input v-model="resultX" /><br><br>
    </el-collapse><br>
  </div>
</template>

<script>
    export default {
        name: 'BandwidthCalc',
        data: function() {
            return {
                Hinput: '4400',
                Vinput: '2250',
                refresh: '60',
                bitDepth: '10',
                sampling: '3',
                cable: '80.0',
                options: [{
                  value: '8',
                  label: '8 bit (per color)'
                  }, {
                  value: '10',
                  label: '10 bit (per color)'
                  },{
                  value: '12',
                  label: '12 bit (per color)'
                  }
                ],
                samplingOptions: [{
                  value: '2',
                  label: '4:2:2'
                  }, {
                  value: '3',
                  label: '4:4:4/RGB'
                  }],
                value: ''
            }
        },
        computed: {
            resultX: {
                get() {
                    var actualValue = ((((this.Hinput*this.Vinput*this.refresh*this.bitDepth*this.sampling)/1000000000)).toFixed(2) + " Gbit/second");
                    return actualValue;
                },
                set(val) {
                    this.actualValue = val;
                }
            }
        }
    }
</script>

<style>
</style>



