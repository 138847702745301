<template>
  <div>
    <div>
      <transition name="fade">
        <learningAbout
          v-show="learningAboutActive"
          id="learningAboutDiv"
        />
      </transition>
      <transition name="fade">
        <beginnerPage
          v-show="beginnerPageActive"
          id="beginnerPageDiv"
          style="margin-top:2%"
        />
      </transition>
      <!-- Below are the Advanced and Specialty pages -->
      <!-- <transition name="fade">
        <advancedPage
          v-show="advancedPageActive"
          id="advancedPageDiv"
          style="margin-top:1%"
        />
      </transition>
      <transition name="fade">
        <specialtyPage
          v-show="specialtyPageActive"
          id="specialtyPageDiv"
          style="margin-top:1%"
        />
      </transition> -->
    </div>
  </div>
</template>

<script>
import beginnerPage from '../components/learningCornerComponents/beginnerPage.vue'
import learningAbout from '../components/learningCornerComponents/learningAbout.vue'
// import specialtyPage from '../components/learningCornerComponents/specialtyPage.vue'
// import advancedPage from '../components/learningCornerComponents/advancedPage.vue'

export default {
  name: 'LearningCorner',
  components: {
      beginnerPage,
      learningAbout
      // specialtyPage,
      // advancedPage,
  },
  data() {
    return {
      beginnerPageActive: true,
      specialtyPageActive: true,
      advancedPageActive: true,
      learningAboutActive: true
    }
  }
}
</script>
<style>

</style>